<template>
  <div class="join-team">
    <div class="page-content">
      <div class="page-logo">
        <img id="spartan-logo" src="../../assets/spartan_logo.svg" alt="logo" />
        <img
          id="spartan-tm"
          src="../../assets/spartan_tm.svg"
          alt="spartan tm"
        />
      </div>
      <div class="page-title">
        <span class="mem-show-desktop">{{ $t("join_team_lp_title") }}</span>
        <span class="mem-show-mobile">
          {{ $t("join_team_lp_title_mobile") }}
        </span>
      </div>
      <team-hero-block :team="team" :readOnly="true" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import TeamHeroBlock from "./components/TeamHeroBlock.vue";

export default {
  data: () => ({
    isLoading: true,
  }),
  computed: {
    ...mapGetters(["team"]),
  },
  components: {
    TeamHeroBlock,
  },
  methods: {
    ...mapActions(["getTeamByInviteCode"]),
  },
  async mounted() {
    let code = this.$router.currentRoute.params.inviteCode;
    let result = await this.getTeamByInviteCode(code);
    this.isLoading = false;
    console.log(result, "result");

    // redirect to team page if already joined
    if (this.team.joined || this.team.blocked)
      this.$router.push({
        name: "teamLeaderboard",
        params: { id: this.team.slug },
      });
  },
};
</script>
<style lang="scss" scoped>
.join-team {
  display: flex;
  flex-direction: column;
  align-items: center;
  .page-content {
    max-width: 722px;
    width: 100%;
    padding-top: 40px;
    .page-logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 78px;
      @media screen and (max-width: 499px) {
        margin-bottom: 56px;
      }
      #spartan-logo {
        height: 40px;
        width: 40px;
        background-color: #cf1019;
        border-radius: 100px;
      }
      #spartan-tm {
        height: 40px;
        width: 160px;
      }
    }
    .page-title {
      font-size: 24px;
      line-height: 28px;
      font-weight: bold;
      text-transform: capitalize;
      margin-bottom: 34px;
      @media screen and (max-width: 499px) {
        font-size: 22px;
        padding: 0 20px;
        margin-bottom: 30px;
      }
    }
  }
}
</style>